import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import '../styles/templates/pageDetailMovies.css'
import Control from "../components/control";
import ReactMarkdown from "react-markdown";
import '../styles/theme/font-size.css'
import {Helmet} from "react-helmet";
import '../styles/theme/global.css'
import Vimeo from "@u-wave/react-vimeo";

const pageDetailMovies = ({data, pageContext}) => {
    const movies = data.strapiPageMovies
    const {next, prev} = pageContext
    return (
        <>
            <Helmet>
                <title>
                    Ebisu Records - {movies.MoviesTitle}
                </title>
                <meta property={'og:image'} content={`https://www.ebisurecords.fr${movies.ImageSeo.publicURL}`}/>
                <meta name={"description"} content={movies.DescriptionSeo}/>
                <meta property={"og:title"} content={movies.TitleSeo}/>
                <meta property={"og:type"} content={"website"}/>
                <meta property={'og:description'} content={movies.DescriptionSeo}/>
                <meta property={"og:url"} content={'https://www.ebisurecords.fr'}/>
                <meta property={"og:site_name"} content={"ebisurecords"}/>
                <meta property={"og:locale"} content={"fr_FR"}/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content={movies.TitleSeo}/>
                <meta property="twitter:description" content={movies.DescriptionSeo}/>
                <meta property="twitter:image"
                      content={`https://www.ebisurecords.fr${movies.ImageSeo.publicURL}`}/>
            </Helmet>
            <Header data={data}/>
            <Layout className={'page-movies'}>
                <section
                    className={'container-detail-information-product d-flex flex-column justify-content-between control-spacing'}>
                    <div>
                        <div className={'container-video embed-container'}>
                            {/*<iframe className="embed-responsive-item col-12" src={`https://player.vimeo.com/video/${movies.videoId}?`}*/}
                            {/*        allowFullScreen frameBorder="0"*/}
                            {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                            {/*        title={'video ads'}/>*/}
                            <Vimeo video={movies.videoId}/>

                        </div>
                        <div className={'container-page d-flex col-12 justify-content-between mt-2'}
                             style={{color: "white"}}>
                            <div className={'container-information'}>
                                <div className={'text-very-small'}>
                                    <div className={'logo-detail-page'} style={{
                                        backgroundImage: `url('${movies.MoviesLogo.publicURL}')`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain"
                                    }}><></>
                                    </div>
                                </div>
                            </div>


                            <div className={'container-description text-very-small col-lg-6 pt-3'}>
                                <div className={"container-movies-title"}>
                                    <h1 className={"text-small mb-2"}>{movies.MoviesTitle}</h1>
                                </div>
                                <ReactMarkdown>{movies.Description}</ReactMarkdown>
                            </div>


                            <div className={'information-partenaire d-flex text-very-small pt-3'}>
                                <div className={'d-flex flex-column justify-content-between container-text-info'}>
                                    <div className={'mr-4 container-text-info'}>
                                            <span
                                                style={{color: 'grey'}}>{movies.InformationProduction[0] ? `${movies.InformationProduction[0].InformationLabel}: ` : null}</span>{movies.InformationProduction[0] ? movies.InformationProduction[0].InformationContent : null}
                                    </div>
                                    <div>
                                        <span
                                            style={{color: 'grey'}}>{movies.InformationProduction[2] ? `${movies.InformationProduction[2].InformationLabel}: ` : null}</span>{movies.InformationProduction[2] ? movies.InformationProduction[2].InformationContent : null}
                                    </div>
                                </div>
                                <div className={'d-flex flex-column justify-content-between container-text-info'}>
                                    <div className={'mr-4 container-text-info'}>
                                            <span
                                                style={{color: 'grey'}}>{movies.InformationProduction[1] ? `${movies.InformationProduction[1].InformationLabel}: ` : null}</span>{movies.InformationProduction[1] ? movies.InformationProduction[1].InformationContent : null}
                                    </div>
                                    <div><span
                                        style={{color: 'grey'}}>{movies.InformationProduction[3] ? `${movies.InformationProduction[3].InformationLabel}: ` : null}</span>{movies.InformationProduction[3] ? movies.InformationProduction[3].InformationContent : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Control classNames={'Controler'}
                             prev={prev} next={next} pathPrev={prev ? prev.slug : null}
                             pathNext={next ? next.slug : null}
                             slug={movies.Categorie}
                             style={{textDecoration: 'none'}}/>
                </section>
            </Layout>
        </>
    )
}

export default pageDetailMovies

export const AdsQuery = graphql`
    query MoviesQuery($slug: String!) {
        strapiPageMovies (
            slug: { eq: $slug }
        ) {
            MoviesTitle
            Categorie
            Description
            videoId
            slug
            id
            MoviesLogo {
                publicURL
            }
            InformationProduction {
              InformationContent
              InformationLabel
            }
            TitleSeo
            ImageSeo {
              publicURL
            }
            DescriptionSeo
        }
        strapiHeader {
            id
            ItemsNav {
                Item
            }
            Logo {
                publicURL
            }
        }
        strapiThemeChooser {
            Themes {
                button
                slug
                id
                title
                imageChooser {
                    publicURL
                }
            }
        }
    }
`
