import React from 'react'
import '../styles/components/control.css'
import {Link} from "gatsby";

const Control = ({slug, next, prev, pathPrev, pathNext, classNames}) => {
    return (
        <section id="control-footer" className={`control-container d-flex justify-content-between pb-3 ${classNames}`}
                 style={{color: "white"}}>
            {
                prev ?
                    <Link to={slug.toLowerCase() === 'ads'? `/brands/${pathPrev}` : `/${slug.toLowerCase()}/${pathPrev}`} className={'control-element'}>
                        <div><i className="fas fa-long-arrow-alt-left"/></div>
                        <div style={{fontSize: "13px"}}>PREV PROJECT</div>
                    </Link>
                    :
                    <div className={'control-element opacity-null'}>
                        <div><i className="fas fa-long-arrow-alt-left"/></div>
                        <div style={{fontSize: "13px"}}>PREV PROJECT</div>
                    </div>
            }
            <Link to={slug.toLowerCase() === 'ads'? `/brands` : `/${slug.toLowerCase()}`} className={'control-element'}>
                <div><i className="fas fa-th"/></div>
                <div style={{fontSize: "13px"}}>BACK TO GALLERY</div>
            </Link>
            {
                next ?
                    <Link to={slug.toLowerCase() === 'ads'? `/brands/${pathNext}` : `/${slug.toLowerCase()}/${pathNext}`} className={'control-element'}>
                        <div><i className="fas fa-long-arrow-alt-right"/></div>
                        <div style={{fontSize: "13px"}}>NEXT PROJECT</div>
                    </Link>
                    :
                    <div className={'control-element opacity-null'}>
                        <div><i className="fas fa-long-arrow-alt-right"/></div>
                        <div style={{fontSize: "13px"}}>NEXT PROJECT</div>
                    </div>
            }

        </section>
    )
}

export default Control